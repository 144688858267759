.home-en-container10 {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.home-en-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-en-video {
  top: 0px;
  left: 0px;
  width: 100%;
  filter: opacity(0.6);
  height: 100%;
  position: absolute;
  box-shadow: 5px 5px 10px 0px #343131;
  object-fit: cover;
}
.home-en-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.home-en-branding1 {
  width: 120px;
  object-fit: cover;
}
.home-en-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-en-hamburger {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: #ffc830;
}
.home-en-hamburger:hover {
  opacity: .5;
}
.home-en-icon10 {
  width: 60px;
  object-fit: cover;
}
.home-en-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.home-en-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-container11 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-en-image10 {
  height: 2rem;
}
.home-en-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-en-icon11 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-en-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-link10 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-en-link10:hover {
  opacity: .5;
}
.home-en-link11 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-en-link11:hover {
  opacity: .5;
}
.home-en-link12 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.home-en-link12:hover {
  opacity: .5;
}
.home-en-link13 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.home-en-link13:hover {
  opacity: .5;
}
.home-en-link14 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-en-link14:hover {
  opacity: .5;
}
.home-en-container12 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.home-en-button1 {
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}
.home-en-icon-group {
  fill: var(--dl-color-gray-white);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
}
.home-en-hero-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-en-container13 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-en-header-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-en-header10 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-heading10 {
  color: var(--dl-color-primary-100);
  font-size: 100px;
  max-width: 790px;
  font-style: normal;
  font-family: "Protest Guerrilla";
  font-weight: 500;
}
.home-en-caption10 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 600px;
  line-height: 36px;
}
.home-en-link15 {
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}
.home-en-text107 {
  letter-spacing: 5px;
  text-transform: uppercase;
}
.home-en-note {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: flex-start;
}
.home-en-caption11 {
  color: rgb(124, 124, 128);
  font-size: 40px;
  max-width: 800px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  margin-right: 158px;
}
.home-en-statistics {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-en-content10 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.home-en-stat1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
}
.home-en-header11 {
  color: rgb(255, 255, 255);
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: 500;
  line-height: 73px;
}
.home-en-caption12 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-en-stat2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
}
.home-en-header12 {
  color: rgb(255, 255, 255);
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-en-caption13 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-en-stat3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-en-header13 {
  color: rgb(255, 255, 255);
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-en-caption14 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-en-slider1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.home-en-heading11 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
}
.home-en-header14 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-selector {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-en-caption15 {
  color: rgb(124, 124, 128);
  width: 1152px;
  font-size: 24px;
  line-height: 36px;
}
.home-en-slides1 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-en-slider2 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-en-slider-container1 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-en-slider-track-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-en-slider-track1 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-en-image11 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-en-content11 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-en-header15 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-en-heading12 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-en-caption16 {
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.home-en-image12 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-en-content12 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-en-header16 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-en-heading13 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-en-caption17 {
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.home-en-image13 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-en-content13 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-en-header17 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-en-heading14 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-en-caption18 {
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.home-en-image14 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-en-content14 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-en-header18 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-en-heading15 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-en-caption19 {
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.home-en-controls1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-en-previous1 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: var(--dl-color-primary-100);
}
.home-en-icon13 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-en-next1 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: var(--dl-color-primary-100);
}
.home-en-icon15 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-en-container15 {
  display: contents;
}
.home-en-objectives {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-en-content15 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  border-top-width: 1px;
}
.home-en-text125 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-en-objectives-list {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-en-text126 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.home-en-text127 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.home-en-text132 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.home-en-text133 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.home-en-text136 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.home-en-text137 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.home-en-experience1 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-en-content16 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-en-header19 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  border-bottom-width: 1px;
}
.home-en-header-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-en-header20 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-heading16 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-en-caption20 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
}
.home-en-testimonial1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-en-content17 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-en-text141 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-en-text142 {
  color: rgb(255, 255, 255);
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 5%;
}
.home-en-information1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-en-author1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-color: #7C7C80;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
  border-right-width: 1px;
}
.home-en-icon17 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-en-name1 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-en-name2 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 400;
  line-height: 27px;
}
.home-en-images1 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: flex-end;
}
.home-en-square1 {
  left: -35px;
  width: 180px;
  bottom: -35px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  aspect-ratio: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-100);
}
.home-en-image15 {
  width: 575px;
  z-index: 100;
  object-fit: cover;
}
.home-en-experience2 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-en-images2 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: flex-end;
}
.home-en-square2 {
  left: -35px;
  width: 180px;
  bottom: -35px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  aspect-ratio: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-100);
}
.home-en-image16 {
  width: 575px;
  z-index: 100;
  object-fit: cover;
}
.home-en-content18 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-en-header21 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  border-bottom-width: 1px;
}
.home-en-header-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-en-header22 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-heading17 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-en-caption21 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
}
.home-en-checkmarks {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-en-check1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-en-mark1 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.home-en-icon18 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-en-text147 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.home-en-check2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-en-mark2 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.home-en-icon20 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-en-text151 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.home-en-check3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-en-mark3 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.home-en-icon22 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-en-text154 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.home-en-check4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-en-mark4 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.home-en-icon24 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-en-text157 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.home-en-testimonial2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-en-content19 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-en-text160 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-en-text161 {
  color: rgb(255, 255, 255);
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 5%;
}
.home-en-information2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-en-author2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-color: #7C7C80;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
  border-right-width: 1px;
}
.home-en-icon26 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-en-name3 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-en-name4 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 400;
  line-height: 27px;
}
.home-en-create {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-en-content20 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.home-en-header23 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-en-heading18 {
  color: rgb(255, 255, 255);
  font-size: 100px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.home-en-text163 {
  font-weight: 900;
}
.home-en-link16 {
  text-decoration: none;
  background-color: #000000;
}
.home-en-comparision {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-en-header-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-en-header24 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-heading19 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-en-caption22 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  text-align: center;
  line-height: 36px;
}
.home-en-caption23 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  text-align: center;
  line-height: 36px;
}
.home-en-caption24 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  text-align: center;
  line-height: 36px;
}
.home-en-table {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-en-row10 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-plans10 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-row11 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-en-text180 {
  color: rgba(255, 255, 255, 0);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-en-text181 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.home-en-row12 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-plans11 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-row13 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-en-text184 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-en-row14 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-plans12 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-row15 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-en-text185 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-en-row16 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-plans13 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-row17 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-en-text186 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-en-text187 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-en-row18 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-plans14 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-row19 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-en-text190 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-en-text191 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-en-row20 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-plans15 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-row21 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-en-text192 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-en-text193 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-en-row22 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-plans16 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-row23 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-en-text194 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-en-text195 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-en-row24 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-plans17 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-row25 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-en-text196 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-en-text197 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-en-row26 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-plans18 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-row27 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-en-text198 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-en-text199 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-en-row28 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-plans19 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-row29 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-en-text200 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-en-text201 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-en-customer {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-en-header25 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  flex-direction: column;
}
.home-en-heading20 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
}
.home-en-slides2 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-en-slider-container2 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-en-slider-track-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-en-slider-track2 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-en-content21 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-en-quote1 {
  gap: var(--dl-space-space-threeunits);
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-en-quote2 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  line-height: 24px;
  letter-spacing: 1%;
}
.home-en-author3 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-name5 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-en-location1 {
  color: rgba(255, 255, 255, 0.3);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-en-content22 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-en-quote3 {
  gap: var(--dl-space-space-threeunits);
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-en-quote4 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  line-height: 24px;
  letter-spacing: 1%;
}
.home-en-author4 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-name6 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-en-location2 {
  color: rgba(255, 255, 255, 0.3);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-en-content23 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-en-quote5 {
  gap: var(--dl-space-space-threeunits);
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-en-quote6 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  line-height: 24px;
  letter-spacing: 1%;
}
.home-en-author5 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-name7 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-en-location3 {
  color: rgba(255, 255, 255, 0.3);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-en-content24 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-en-quote7 {
  gap: var(--dl-space-space-threeunits);
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-en-quote8 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  line-height: 24px;
  letter-spacing: 1%;
}
.home-en-author6 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-name8 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-en-location4 {
  color: rgba(255, 255, 255, 0.3);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-en-controls2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-en-previous2 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: var(--dl-color-primary-100);
}
.home-en-icon27 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-en-next2 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: var(--dl-color-primary-100);
}
.home-en-icon29 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-en-link17 {
  text-decoration: none;
}
.home-en-container17 {
  display: contents;
}
.home-en-faq {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.home-en-header26 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-heading21 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-en-content25 {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-en-column1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-en-element1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-en-header27 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-en-content26 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-en-link18 {
  color: var(--dl-color-primary-100);
  text-decoration: underline;
}
.home-en-element2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-en-header28 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-en-content27 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-en-element3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-en-header29 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-en-content28 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-en-element4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-en-header30 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-en-content29 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-en-column2 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-en-element5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-en-header31 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-en-content30 {
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-en-text237 {
  color: #7c7c80;
}
.home-en-link19 {
  color: var(--dl-color-primary-100);
  text-decoration: underline;
}
.home-en-element6 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-en-header32 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-en-content31 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-en-element7 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-en-header33 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-en-content32 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-en-element8 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-en-header34 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-en-content33 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-en-content34 {
  color: var(--dl-color-primary-100);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
  text-decoration: underline;
}
.home-en-link21 {
  display: contents;
}
.home-en-content35 {
  color: var(--dl-color-primary-100);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
  text-decoration: underline;
}
.home-en-container18 {
  align-self: center;
}
.home-en-container19 {
  display: contents;
}
.home-en-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-en-content36 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 1px;
}
.home-en-main {
  gap: 240px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-en-branding2 {
  gap: var(--dl-space-space-twounits);
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-image17 {
  width: 120px;
  object-fit: cover;
}
.home-en-text249 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.home-en-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-en-column3 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-header35 {
  font-size: 24px;
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.home-en-list1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-link22 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-en-link22:hover {
  opacity: .5;
}
.home-en-link23 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-en-link23:hover {
  opacity: .5;
}
.home-en-link24 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.home-en-link24:hover {
  opacity: .5;
}
.home-en-link25 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-en-link25:hover {
  opacity: .5;
}
.home-en-column4 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-header36 {
  font-size: 24px;
  font-style: italic;
  font-family: "Playfair Display";
  font-weight: 500;
}
.home-en-list2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-en-link26 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-en-link26:hover {
  opacity: .5;
}
.home-en-link27 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-en-link27:hover {
  opacity: .5;
}
.home-en-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-en-container20 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-en-image18 {
  width: var(--dl-size-size-xsmall);
  object-fit: cover;
}
.home-en-text257 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
}
.home-en-button2 {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 0px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
.home-en-image19 {
  width: 21px;
  object-fit: cover;
}
.home-en-container22 {
  display: contents;
}
.home-en-container23 {
  display: block !important;
}
.home-en-container24 {
  display: contents;
}
@media(max-width: 1600px) {
  .home-en-link12 {
    font-size: 16px;
    line-height: 32px;
  }
  .home-en-link13 {
    font-size: 16px;
    line-height: 32px;
  }
  .home-en-link14 {
    font-size: 16px;
    line-height: 32px;
  }
  .home-en-pasted-image1 {
    height: 25px;
  }
  .home-en-caption15 {
    color: rgb(124, 124, 128);
    font-size: 24px;
    line-height: 36px;
  }
  .home-en-content30 {
    color: rgb(124, 124, 128);
    line-height: 24px;
  }
  .home-en-text249 {
    color: rgb(124, 124, 128);
    line-height: 24px;
  }
}
@media(max-width: 1200px) {
  .home-en-caption11 {
    color: rgb(124, 124, 128);
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
  }
  .home-en-header11 {
    color: rgb(255, 255, 255);
    font-size: 62px;
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 500;
    line-height: 73px;
  }
  .home-en-caption12 {
    color: rgb(124, 124, 128);
  }
  .home-en-header12 {
    color: rgb(255, 255, 255);
    font-size: 62px;
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 500;
    line-height: 73px;
  }
  .home-en-caption13 {
    color: rgb(124, 124, 128);
  }
  .home-en-header13 {
    color: rgb(255, 255, 255);
    font-size: 62px;
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 500;
    line-height: 73px;
  }
  .home-en-caption14 {
    color: rgb(124, 124, 128);
    font-size: 16px;
    line-height: 24px;
  }
  .home-en-heading11 {
    color: rgb(255, 255, 255);
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
  }
  .home-en-caption15 {
    color: rgb(124, 124, 128);
  }
  .home-en-text125 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
  }
  .home-en-text126 {
    color: rgb(255, 255, 255);
  }
  .home-en-text127 {
    color: rgb(124, 124, 128);
    line-height: 24px;
  }
  .home-en-text132 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  .home-en-text133 {
    color: rgb(124, 124, 128);
    line-height: 24px;
  }
  .home-en-text136 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  .home-en-text137 {
    color: rgb(124, 124, 128);
  }
  .home-en-heading19 {
    color: rgb(255, 255, 255);
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
  }
  .home-en-heading20 {
    color: rgb(255, 255, 255);
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
  }
  .home-en-quote6 {
    color: rgb(124, 124, 128);
    font-size: 16px;
    line-height: 24px;
  }
  .home-en-header27 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-en-header31 {
    color: rgb(255, 255, 255);
  }
  .home-en-content30 {
    color: rgb(124, 124, 128);
  }
  .home-en-link19 {
    text-decoration: underline;
  }
  .home-en-header32 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-en-content31 {
    color: rgb(124, 124, 128);
  }
  .home-en-header33 {
    color: rgb(255, 255, 255);
  }
  .home-en-content32 {
    color: rgb(124, 124, 128);
    line-height: 24px;
  }
  .home-en-header34 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-en-content33 {
    color: rgb(124, 124, 128);
  }
  .home-en-content34 {
    color: rgb(124, 124, 128);
  }
  .home-en-link20 {
    text-decoration: underline;
  }
  .home-en-content35 {
    color: rgb(124, 124, 128);
    line-height: 24px;
    text-decoration: underline;
  }
  .home-en-link27 {
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .home-en-text257 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
  }
}
@media(max-width: 991px) {
  .home-en-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-hero-content {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-content10 {
    padding-top: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-en-stat1 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-en-stat2 {
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-en-stat3 {
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-selector {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-en-slider2 {
    gap: var(--dl-space-space-twounits);
  }
  .home-en-image11 {
    width: 100%;
    height: 120px;
  }
  .home-en-content11 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-en-header15 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-en-heading12 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  .home-en-caption16 {
    max-width: 600px;
  }
  .home-en-image12 {
    width: 100%;
    height: 120px;
  }
  .home-en-content12 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-en-header16 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-en-heading13 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  .home-en-caption17 {
    max-width: 600px;
  }
  .home-en-image13 {
    width: 100%;
    height: 120px;
  }
  .home-en-content13 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-en-header17 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-en-heading14 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  .home-en-caption18 {
    max-width: 600px;
  }
  .home-en-image14 {
    width: 100%;
    height: 120px;
  }
  .home-en-content14 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-en-header18 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-en-heading15 {
    font-size: 24px;
  }
  .home-en-caption19 {
    max-width: 600px;
  }
  .home-en-content15 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-en-objectives-list {
    padding-right: 0px;
  }
  .home-en-objective2 {
    flex: 1;
  }
  .home-en-objective3 {
    flex: 1;
  }
  .home-en-experience1 {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .home-en-experience2 {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .home-en-content20 {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-en-heading18 {
    font-size: 50px;
  }
  .home-en-row11 {
    padding-right: 0px;
    justify-content: center;
  }
  .home-en-text180 {
    color: rgba(255, 255, 255, 0);
    width: 0px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
  .home-en-row12 {
    flex-direction: column;
  }
  .home-en-plans11 {
    width: 100%;
  }
  .home-en-row13 {
    padding-right: 0px;
  }
  .home-en-text184 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-en-row14 {
    flex-direction: column;
  }
  .home-en-plans12 {
    width: 100%;
  }
  .home-en-row15 {
    padding-right: 0px;
  }
  .home-en-text185 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-en-row16 {
    flex-direction: column;
  }
  .home-en-plans13 {
    width: 100%;
  }
  .home-en-row17 {
    padding-right: 0px;
  }
  .home-en-text186 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-en-row18 {
    flex-direction: column;
  }
  .home-en-plans14 {
    width: 100%;
  }
  .home-en-row19 {
    padding-right: 0px;
  }
  .home-en-text190 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-en-row20 {
    flex-direction: column;
  }
  .home-en-plans15 {
    width: 100%;
  }
  .home-en-row21 {
    padding-right: 0px;
  }
  .home-en-text192 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-en-row22 {
    flex-direction: column;
  }
  .home-en-plans16 {
    width: 100%;
  }
  .home-en-row23 {
    padding-right: 0px;
  }
  .home-en-text194 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-en-row24 {
    flex-direction: column;
  }
  .home-en-plans17 {
    width: 100%;
  }
  .home-en-row25 {
    padding-right: 0px;
  }
  .home-en-text196 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-en-row26 {
    flex-direction: column;
  }
  .home-en-plans18 {
    width: 100%;
  }
  .home-en-row27 {
    padding-right: 0px;
  }
  .home-en-text198 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-en-row28 {
    flex-direction: column;
  }
  .home-en-plans19 {
    width: 100%;
  }
  .home-en-row29 {
    padding-right: 0px;
  }
  .home-en-text200 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-en-customer {
    max-width: 1400px;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .home-en-header25 {
    max-width: 100%;
  }
  .home-en-heading20 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-en-content21 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-en-quote1 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-en-quote2 {
    font-size: 16px;
    max-width: 100%;
  }
  .home-en-content22 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-en-quote3 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-en-quote4 {
    font-size: 16px;
    max-width: 100%;
  }
  .home-en-content23 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-en-quote5 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-en-quote6 {
    font-size: 16px;
    max-width: 100%;
  }
  .home-en-content24 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-en-quote7 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-en-quote8 {
    font-size: 16px;
    max-width: 100%;
  }
  .home-en-faq {
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-en-content25 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-en-column1 {
    max-width: 100%;
  }
  .home-en-header27 {
    font-size: 24px;
  }
  .home-en-content26 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-text220 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-text221 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-text222 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-text223 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-link18 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-header28 {
    font-size: 24px;
  }
  .home-en-content27 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-header29 {
    font-size: 24px;
  }
  .home-en-content28 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-header30 {
    font-size: 24px;
  }
  .home-en-content29 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-column2 {
    max-width: 100%;
  }
  .home-en-header31 {
    font-size: 24px;
  }
  .home-en-content30 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-header32 {
    font-size: 24px;
  }
  .home-en-content31 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-header33 {
    font-size: 24px;
  }
  .home-en-content32 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-header34 {
    font-size: 24px;
  }
  .home-en-content33 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-text244 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-content34 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-content35 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-content36 {
    border-top-width: 0px;
  }
  .home-en-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-en-branding2 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .home-en-burger-menu {
    display: flex;
  }
  .home-en-heading10 {
    color: var(--dl-color-primary-100);
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
  }
  .home-en-note {
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-en-caption11 {
    font-size: 18px;
    max-width: 90%;
    line-height: 23px;
    margin-right: 0px;
  }
  .home-en-statistics {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-en-stat1 {
    gap: var(--dl-space-space-unit);
  }
  .home-en-header11 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-en-caption12 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-stat2 {
    gap: var(--dl-space-space-unit);
  }
  .home-en-header12 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-en-caption13 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-stat3 {
    gap: var(--dl-space-space-unit);
  }
  .home-en-header13 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-en-caption14 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-slider1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-en-heading11 {
    font-size: 40px;
  }
  .home-en-caption15 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-en-slides1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-slider2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-slider-track1 {
    align-items: stretch;
  }
  .home-en-heading12 {
    font-size: 18px;
  }
  .home-en-caption16 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-heading13 {
    font-size: 18px;
  }
  .home-en-caption17 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-heading14 {
    font-size: 18px;
  }
  .home-en-caption18 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-heading15 {
    font-size: 18px;
  }
  .home-en-caption19 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-previous1 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-en-icon13 {
    width: 14px;
    height: 14px;
  }
  .home-en-next1 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-en-icon15 {
    width: 14px;
    height: 14px;
  }
  .home-en-objectives {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-content15 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-en-objectives-list {
    width: 100%;
    flex-direction: column;
  }
  .home-en-experience1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-en-content16 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-header19 {
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-header-container2 {
    gap: var(--dl-space-space-unit);
  }
  .home-en-heading16 {
    font-size: 40px;
  }
  .home-en-caption20 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-en-text141 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-icon17 {
    width: 40px;
    height: 40px;
  }
  .home-en-name1 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-name2 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-square1 {
    left: -20px;
    width: 20%;
    bottom: -20px;
  }
  .home-en-image15 {
    width: 100%;
  }
  .home-en-experience2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-en-square2 {
    left: -20px;
    width: 20%;
    bottom: -20px;
  }
  .home-en-image16 {
    width: 100%;
  }
  .home-en-content18 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-header21 {
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-header-container3 {
    gap: var(--dl-space-space-unit);
  }
  .home-en-heading17 {
    font-size: 40px;
  }
  .home-en-caption21 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-en-text160 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-icon26 {
    width: 40px;
    height: 40px;
  }
  .home-en-name3 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-name4 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-create {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-en-content20 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-heading18 {
    font-size: 36px;
  }
  .home-en-comparision {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-en-header-container4 {
    gap: var(--dl-space-space-unit);
  }
  .home-en-header24 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .home-en-heading19 {
    font-size: 40px;
  }
  .home-en-caption22 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-en-caption23 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-en-caption24 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-en-text181 {
    font-size: 12px;
    line-height: 14px;
  }
  .home-en-text184 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-text185 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-text186 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-text187 {
    line-height: 21px;
  }
  .home-en-text188 {
    line-height: 21px;
  }
  .home-en-text190 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-text191 {
    line-height: 21px;
  }
  .home-en-text192 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-text193 {
    line-height: 21px;
  }
  .home-en-text194 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-text195 {
    line-height: 21px;
  }
  .home-en-text196 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-text197 {
    line-height: 21px;
  }
  .home-en-text198 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-text199 {
    line-height: 21px;
  }
  .home-en-text200 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-text201 {
    line-height: 21px;
  }
  .home-en-customer {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-en-heading20 {
    font-size: 40px;
  }
  .home-en-slides2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-slider-track2 {
    align-items: stretch;
  }
  .home-en-quote1 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-en-quote2 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-author3 {
    width: 100%;
  }
  .home-en-name5 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-location1 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-quote3 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-en-quote4 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-author4 {
    width: 100%;
  }
  .home-en-name6 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-location2 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-quote5 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-en-quote6 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-author5 {
    width: 100%;
  }
  .home-en-name7 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-location3 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-quote7 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-en-quote8 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-author6 {
    width: 100%;
  }
  .home-en-name8 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-location4 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-en-previous2 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-en-icon27 {
    width: 14px;
    height: 14px;
  }
  .home-en-next2 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-en-icon29 {
    width: 14px;
    height: 14px;
  }
  .home-en-faq {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-en-header26 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .home-en-heading21 {
    font-size: 36px;
  }
  .home-en-column1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-element1 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-en-header27 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-en-element2 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-en-header28 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-en-element3 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-en-header29 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-en-element4 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-en-header30 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-en-column2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-element5 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-en-header31 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-en-element6 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-en-header32 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-en-element7 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-en-header33 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-en-element8 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-en-header34 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-en-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-en-links {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .home-en-branding1 {
    padding-top: 1px;
  }
  .home-en-hamburger {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-en-icon10 {
    width: 30px;
  }
  .home-en-mobile-menu {
    padding: 16px;
  }
  .home-en-button1 {
    text-align: center;
  }
  .home-en-hero-content {
    gap: var(--dl-space-space-threeunits);
  }
  .home-en-heading10 {
    color: var(--dl-color-primary-100);
    font-size: 50px;
    align-self: flex-start;
    font-style: normal;
    text-align: center;
    font-weight: 500;
  }
  .home-en-text103 {
    font-weight: 900;
  }
  .home-en-caption10 {
    font-size: 16px;
    text-align: center;
    line-height: 24px;
  }
  .home-en-link15 {
    text-align: center;
  }
  .home-en-heading11 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
  }
  .home-en-caption15 {
    width: 100%;
  }
  .home-en-text125 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-en-text126 {
    font-size: 18px;
  }
  .home-en-text132 {
    font-size: 18px;
  }
  .home-en-text136 {
    font-size: 18px;
  }
  .home-en-heading16 {
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
  }
  .home-en-caption20 {
    color: rgb(124, 124, 128);
    font-size: 16px;
    line-height: 24px;
  }
  .home-en-heading17 {
    color: rgb(255, 255, 255);
  }
  .home-en-heading18 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
  }
  .home-en-text163 {
    font-weight: 900;
  }
  .home-en-link16 {
    text-align: center;
  }
  .home-en-header24 {
    gap: 0px;
    flex-direction: column;
  }
  .home-en-heading19 {
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-style: normal;
    text-align: center;
    font-weight: 400;
  }
  .home-en-text181 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
  }
  .home-en-text187 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    line-height: 32px;
  }
  .home-en-heading20 {
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
  }
  .home-en-slides2 {
    width: 100%;
  }
  .home-en-slider-container2 {
    width: 100%;
  }
  .home-en-quote8 {
    color: rgb(124, 124, 128);
    font-size: 16px;
    line-height: 24px;
  }
  .home-en-link17 {
    text-align: center;
  }
  .home-en-header26 {
    gap: 0px;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-en-heading21 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
  }
  .home-en-header29 {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-en-links {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-en-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .home-en-button2 {
    justify-content: center;
  }
}
