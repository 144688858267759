.home-es-container10 {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.home-es-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-es-video {
  top: 0px;
  left: 0px;
  width: 100%;
  filter: opacity(0.6);
  height: 100%;
  position: absolute;
  box-shadow: 5px 5px 10px 0px #343131;
  object-fit: cover;
}
.home-es-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.home-es-branding1 {
  width: 120px;
  object-fit: cover;
}
.home-es-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-es-hamburger {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: #ffc830;
}
.home-es-hamburger:hover {
  opacity: .5;
}
.home-es-icon10 {
  width: 60px;
  object-fit: cover;
}
.home-es-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.home-es-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-container11 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-es-image10 {
  height: 2rem;
}
.home-es-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-es-icon11 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-es-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-link10 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-es-link10:hover {
  opacity: .5;
}
.home-es-link11 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-es-link11:hover {
  opacity: .5;
}
.home-es-link12 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.home-es-link12:hover {
  opacity: .5;
}
.home-es-link13 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.home-es-link13:hover {
  opacity: .5;
}
.home-es-link14 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-es-link14:hover {
  opacity: .5;
}
.home-es-container12 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.home-es-button1 {
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}
.home-es-icon-group {
  fill: var(--dl-color-gray-white);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
}
.home-es-hero-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-es-container13 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-es-header-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-es-header10 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-heading10 {
  color: var(--dl-color-primary-100);
  font-size: 100px;
  max-width: 790px;
  font-style: normal;
  font-family: "Protest Guerrilla";
  font-weight: 500;
}
.home-es-caption10 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 600px;
  line-height: 36px;
}
.home-es-link15 {
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}
.home-es-text15 {
  letter-spacing: 5px;
  text-transform: uppercase;
}
.home-es-note {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: flex-start;
}
.home-es-caption11 {
  color: #7C7C80;
  font-size: 40px;
  max-width: 800px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  margin-right: 158px;
}
.home-es-statistics {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-es-content10 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.home-es-stat1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
}
.home-es-header11 {
  color: rgb(255, 255, 255);
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-es-caption12 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-es-stat2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  border-right-width: 1px;
}
.home-es-header12 {
  color: rgb(255, 255, 255);
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-es-caption13 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-es-stat3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-es-header13 {
  color: rgb(255, 255, 255);
  font-size: 62px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-es-caption14 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-es-slider1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.home-es-heading11 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
}
.home-es-header14 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-selector {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-es-caption15 {
  color: rgb(124, 124, 128);
  width: 1152px;
  font-size: 24px;
  line-height: 36px;
}
.home-es-slides1 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-es-slider2 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-es-slider-container1 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-es-slider-track-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-es-slider-track1 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-es-image11 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-es-content11 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-es-header15 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-es-heading12 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-es-caption16 {
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.home-es-image12 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-es-content12 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-es-header16 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-es-heading13 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-es-caption17 {
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.home-es-image13 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-es-content13 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-es-header17 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-es-heading14 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-es-caption18 {
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.home-es-image14 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-es-content14 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-es-header18 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-es-heading15 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-es-caption19 {
  color: var(--dl-color-gray-white);
  max-width: 600px;
  line-height: 24px;
}
.home-es-controls1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-es-previous1 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: var(--dl-color-primary-100);
}
.home-es-icon13 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-es-next1 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: var(--dl-color-primary-100);
}
.home-es-icon15 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-es-container15 {
  display: contents;
}
.home-es-objectives {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-es-content15 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  border-top-width: 1px;
}
.home-es-text20 {
  color: #7C7C80;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-es-objectives-list {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-es-text21 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.home-es-text22 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.home-es-text25 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.home-es-text26 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.home-es-text27 {
  color: #ffffff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.home-es-text28 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.home-es-experience1 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-es-content16 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-es-header19 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  border-bottom-width: 1px;
}
.home-es-header-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-es-header20 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-heading16 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-es-caption20 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
}
.home-es-testimonial1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-es-content17 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-es-text31 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-es-text32 {
  color: rgb(255, 255, 255);
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 5%;
}
.home-es-information1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-es-author1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-color: #7C7C80;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
  border-right-width: 1px;
}
.home-es-icon17 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-es-name1 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-es-name2 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 400;
  line-height: 27px;
}
.home-es-images1 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: flex-end;
}
.home-es-square1 {
  left: -35px;
  width: 180px;
  bottom: -35px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  aspect-ratio: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-100);
}
.home-es-image15 {
  width: 575px;
  z-index: 100;
  object-fit: cover;
}
.home-es-experience2 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-es-images2 {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: flex-end;
}
.home-es-square2 {
  left: -35px;
  width: 180px;
  bottom: -35px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  aspect-ratio: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-100);
}
.home-es-image16 {
  width: 575px;
  z-index: 100;
  object-fit: cover;
}
.home-es-content18 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-es-header21 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  border-bottom-width: 1px;
}
.home-es-header-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-es-header22 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-heading17 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-es-caption21 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
}
.home-es-checkmarks {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-es-check1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-es-mark1 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.home-es-icon18 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-es-text33 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.home-es-check2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-es-mark2 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.home-es-icon20 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-es-text34 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.home-es-check3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-es-mark3 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.home-es-icon22 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-es-text35 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.home-es-check4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-es-mark4 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.home-es-icon24 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-es-text36 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.home-es-testimonial2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-es-content19 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-es-text37 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-es-text38 {
  color: rgb(255, 255, 255);
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 5%;
}
.home-es-information2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-es-author2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-color: #7C7C80;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
  border-right-width: 1px;
}
.home-es-icon26 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-es-name3 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-es-name4 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 400;
  line-height: 27px;
}
.home-es-create {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-es-content20 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.home-es-header23 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-es-heading18 {
  color: rgb(255, 255, 255);
  font-size: 100px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
}
.home-es-text40 {
  font-weight: 900;
}
.home-es-link16 {
  text-decoration: none;
  background-color: #000000;
}
.home-es-comparision {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-es-header-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-es-header24 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-heading19 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-es-caption22 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  text-align: center;
  line-height: 36px;
}
.home-es-caption23 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  text-align: center;
  line-height: 36px;
}
.home-es-caption24 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  text-align: center;
  line-height: 36px;
}
.home-es-table {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-es-row10 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-plans10 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-row11 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-es-text58 {
  color: rgba(255, 255, 255, 0);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-es-text59 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.home-es-row12 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-plans11 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-row13 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-es-text62 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-es-row14 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-plans12 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-row15 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-es-text63 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-es-row16 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-plans13 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-row17 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-es-text64 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-es-text65 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-es-row18 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-plans14 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-row19 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-es-text68 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-es-text69 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-es-row20 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-plans15 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-row21 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-es-text70 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-es-text71 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-es-row22 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-plans16 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-row23 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-es-text72 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-es-text73 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-es-row24 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-plans17 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-row25 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-es-text74 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-es-text75 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-es-row26 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-plans18 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-row27 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-es-text76 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-es-text77 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-es-row28 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-plans19 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-row29 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-es-text78 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-es-text79 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-es-customer {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-es-header25 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  flex-direction: column;
}
.home-es-heading20 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
}
.home-es-slides2 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-es-slider-container2 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-es-slider-track-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-es-slider-track2 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-es-content21 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-es-quote1 {
  gap: var(--dl-space-space-threeunits);
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-es-quote2 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  line-height: 24px;
  letter-spacing: 1%;
}
.home-es-author3 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-name5 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-es-location1 {
  color: rgba(255, 255, 255, 0.3);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-es-content22 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-es-quote3 {
  gap: var(--dl-space-space-threeunits);
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-es-quote4 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  line-height: 24px;
  letter-spacing: 1%;
}
.home-es-author4 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-name6 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-es-location2 {
  color: rgba(255, 255, 255, 0.3);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-es-content23 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-es-quote5 {
  gap: var(--dl-space-space-threeunits);
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-es-quote6 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  line-height: 24px;
  letter-spacing: 1%;
}
.home-es-author5 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-name7 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-es-location3 {
  color: rgba(255, 255, 255, 0.3);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-es-content24 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-es-quote7 {
  gap: var(--dl-space-space-threeunits);
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-color: rgba(255, 255, 255, 0.2);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-es-quote8 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  line-height: 24px;
  letter-spacing: 1%;
}
.home-es-author6 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-name8 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1%;
}
.home-es-location4 {
  color: rgba(255, 255, 255, 0.3);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1%;
}
.home-es-controls2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-es-previous2 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: var(--dl-color-primary-100);
}
.home-es-icon27 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-es-next2 {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: var(--dl-color-primary-100);
}
.home-es-icon29 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-es-link17 {
  text-decoration: none;
}
.home-es-container17 {
  display: contents;
}
.home-es-faq {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.home-es-header26 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-heading21 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-es-content25 {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-es-column1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-es-element1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-es-header27 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-es-content26 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-es-link18 {
  color: var(--dl-color-primary-100);
  text-decoration: underline;
}
.home-es-element2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-es-header28 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-es-content27 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-es-element3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-es-header29 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-es-content28 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-es-element4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-es-header30 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-es-content29 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-es-column2 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-es-element5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-es-header31 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-es-content30 {
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-es-text94 {
  color: #7c7c80;
}
.home-es-link19 {
  color: var(--dl-color-primary-100);
  text-decoration: underline;
}
.home-es-element6 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-es-header32 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-es-content31 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-es-element7 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-es-header33 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-es-content32 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-es-element8 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-es-header34 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-es-content33 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-es-content34 {
  color: var(--dl-color-primary-100);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
  text-decoration: underline;
}
.home-es-link21 {
  display: contents;
}
.home-es-content35 {
  color: var(--dl-color-primary-100);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
  text-decoration: underline;
}
.home-es-container18 {
  align-self: center;
}
.home-es-container19 {
  display: contents;
}
.home-es-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-es-content36 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 1px;
}
.home-es-main {
  gap: 240px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-es-branding2 {
  gap: var(--dl-space-space-twounits);
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-image17 {
  width: 120px;
  object-fit: cover;
}
.home-es-text100 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.home-es-links {
  gap: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-es-column3 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-header35 {
  font-size: 24px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.home-es-list1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-link22 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-es-link22:hover {
  opacity: .5;
}
.home-es-link23 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-es-link23:hover {
  opacity: .5;
}
.home-es-link24 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
}
.home-es-link24:hover {
  opacity: .5;
}
.home-es-link25 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-es-link25:hover {
  opacity: .5;
}
.home-es-column4 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-header36 {
  font-size: 24px;
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.home-es-list2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-es-link26 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-es-link26:hover {
  opacity: .5;
}
.home-es-link27 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-es-link27:hover {
  opacity: .5;
}
.home-es-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-es-container20 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-es-image18 {
  width: var(--dl-size-size-xsmall);
  object-fit: cover;
}
.home-es-text108 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
}
.home-es-button2 {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 0px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
.home-es-image19 {
  width: 21px;
  object-fit: cover;
}
.home-es-container22 {
  display: contents;
}
.home-es-container23 {
  display: block !important;
}
.home-es-container24 {
  display: contents;
}
@media(max-width: 1600px) {
  .home-es-link12 {
    font-size: 16px;
    line-height: 32px;
  }
  .home-es-link13 {
    font-size: 16px;
    line-height: 32px;
  }
  .home-es-link14 {
    font-size: 16px;
    line-height: 32px;
  }
  .home-es-pasted-image1 {
    height: 25px;
  }
  .home-es-caption15 {
    color: rgb(124, 124, 128);
    font-size: 24px;
    line-height: 36px;
  }
  .home-es-content30 {
    color: rgb(124, 124, 128);
    line-height: 24px;
  }
  .home-es-text100 {
    color: rgb(124, 124, 128);
    line-height: 24px;
  }
}
@media(max-width: 1200px) {
  .home-es-caption11 {
    color: rgb(124, 124, 128);
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
  }
  .home-es-header11 {
    color: rgb(255, 255, 255);
    font-size: 62px;
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 500;
    line-height: 73px;
  }
  .home-es-caption12 {
    color: rgb(124, 124, 128);
  }
  .home-es-header12 {
    color: rgb(255, 255, 255);
    font-size: 62px;
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 500;
    line-height: 73px;
  }
  .home-es-caption13 {
    color: rgb(124, 124, 128);
  }
  .home-es-header13 {
    color: rgb(255, 255, 255);
    font-size: 62px;
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 500;
    line-height: 73px;
  }
  .home-es-caption14 {
    color: rgb(124, 124, 128);
    font-size: 16px;
    line-height: 24px;
  }
  .home-es-heading11 {
    color: rgb(255, 255, 255);
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
  }
  .home-es-caption15 {
    color: rgb(124, 124, 128);
  }
  .home-es-text20 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
  }
  .home-es-text21 {
    color: rgb(255, 255, 255);
  }
  .home-es-text22 {
    color: rgb(124, 124, 128);
    line-height: 24px;
  }
  .home-es-text25 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  .home-es-text26 {
    color: rgb(124, 124, 128);
    line-height: 24px;
  }
  .home-es-text27 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  .home-es-text28 {
    color: rgb(124, 124, 128);
  }
  .home-es-heading19 {
    color: rgb(255, 255, 255);
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
  }
  .home-es-heading20 {
    color: rgb(255, 255, 255);
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
  }
  .home-es-quote6 {
    color: rgb(124, 124, 128);
    font-size: 16px;
    line-height: 24px;
  }
  .home-es-header27 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-es-header31 {
    color: rgb(255, 255, 255);
  }
  .home-es-content30 {
    color: rgb(124, 124, 128);
  }
  .home-es-link19 {
    text-decoration: underline;
  }
  .home-es-header32 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-es-content31 {
    color: rgb(124, 124, 128);
  }
  .home-es-header33 {
    color: rgb(255, 255, 255);
  }
  .home-es-content32 {
    color: rgb(124, 124, 128);
    line-height: 24px;
  }
  .home-es-header34 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-es-content33 {
    color: rgb(124, 124, 128);
  }
  .home-es-content34 {
    color: rgb(124, 124, 128);
  }
  .home-es-link20 {
    text-decoration: underline;
  }
  .home-es-content35 {
    color: rgb(124, 124, 128);
    line-height: 24px;
    text-decoration: underline;
  }
  .home-es-link27 {
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .home-es-text108 {
    color: rgb(124, 124, 128);
    font-size: 14px;
    line-height: 21px;
  }
}
@media(max-width: 991px) {
  .home-es-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-hero-content {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-content10 {
    padding-top: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-es-stat1 {
    width: 100%;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-es-stat2 {
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-es-stat3 {
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-selector {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-es-slider2 {
    gap: var(--dl-space-space-twounits);
  }
  .home-es-image11 {
    width: 100%;
    height: 120px;
  }
  .home-es-content11 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-es-header15 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-es-heading12 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  .home-es-caption16 {
    max-width: 600px;
  }
  .home-es-image12 {
    width: 100%;
    height: 120px;
  }
  .home-es-content12 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-es-header16 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-es-heading13 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  .home-es-caption17 {
    max-width: 600px;
  }
  .home-es-image13 {
    width: 100%;
    height: 120px;
  }
  .home-es-content13 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-es-header17 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-es-heading14 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  .home-es-caption18 {
    max-width: 600px;
  }
  .home-es-image14 {
    width: 100%;
    height: 120px;
  }
  .home-es-content14 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-es-header18 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-es-heading15 {
    font-size: 24px;
  }
  .home-es-caption19 {
    max-width: 600px;
  }
  .home-es-content15 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-es-objectives-list {
    padding-right: 0px;
  }
  .home-es-objective2 {
    flex: 1;
  }
  .home-es-objective3 {
    flex: 1;
  }
  .home-es-experience1 {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .home-es-experience2 {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .home-es-content20 {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-es-heading18 {
    font-size: 50px;
  }
  .home-es-row11 {
    padding-right: 0px;
    justify-content: center;
  }
  .home-es-text58 {
    color: rgba(255, 255, 255, 0);
    width: 0px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
  .home-es-row12 {
    flex-direction: column;
  }
  .home-es-plans11 {
    width: 100%;
  }
  .home-es-row13 {
    padding-right: 0px;
  }
  .home-es-text62 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-es-row14 {
    flex-direction: column;
  }
  .home-es-plans12 {
    width: 100%;
  }
  .home-es-row15 {
    padding-right: 0px;
  }
  .home-es-text63 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-es-row16 {
    flex-direction: column;
  }
  .home-es-plans13 {
    width: 100%;
  }
  .home-es-row17 {
    padding-right: 0px;
  }
  .home-es-text64 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-es-row18 {
    flex-direction: column;
  }
  .home-es-plans14 {
    width: 100%;
  }
  .home-es-row19 {
    padding-right: 0px;
  }
  .home-es-text68 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-es-row20 {
    flex-direction: column;
  }
  .home-es-plans15 {
    width: 100%;
  }
  .home-es-row21 {
    padding-right: 0px;
  }
  .home-es-text70 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-es-row22 {
    flex-direction: column;
  }
  .home-es-plans16 {
    width: 100%;
  }
  .home-es-row23 {
    padding-right: 0px;
  }
  .home-es-text72 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-es-row24 {
    flex-direction: column;
  }
  .home-es-plans17 {
    width: 100%;
  }
  .home-es-row25 {
    padding-right: 0px;
  }
  .home-es-text74 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-es-row26 {
    flex-direction: column;
  }
  .home-es-plans18 {
    width: 100%;
  }
  .home-es-row27 {
    padding-right: 0px;
  }
  .home-es-text76 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-es-row28 {
    flex-direction: column;
  }
  .home-es-plans19 {
    width: 100%;
  }
  .home-es-row29 {
    padding-right: 0px;
  }
  .home-es-text78 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-es-customer {
    max-width: 1400px;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  .home-es-header25 {
    max-width: 100%;
  }
  .home-es-heading20 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-es-content21 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-es-quote1 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-es-quote2 {
    font-size: 16px;
    max-width: 100%;
  }
  .home-es-content22 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-es-quote3 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-es-quote4 {
    font-size: 16px;
    max-width: 100%;
  }
  .home-es-content23 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-es-quote5 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-es-quote6 {
    font-size: 16px;
    max-width: 100%;
  }
  .home-es-content24 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-es-quote7 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-es-quote8 {
    font-size: 16px;
    max-width: 100%;
  }
  .home-es-faq {
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-es-content25 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-es-column1 {
    max-width: 100%;
  }
  .home-es-header27 {
    font-size: 24px;
  }
  .home-es-content26 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-text89 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-text90 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-text91 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-link18 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-header28 {
    font-size: 24px;
  }
  .home-es-content27 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-header29 {
    font-size: 24px;
  }
  .home-es-content28 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-header30 {
    font-size: 24px;
  }
  .home-es-content29 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-column2 {
    max-width: 100%;
  }
  .home-es-header31 {
    font-size: 24px;
  }
  .home-es-content30 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-header32 {
    font-size: 24px;
  }
  .home-es-content31 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-header33 {
    font-size: 24px;
  }
  .home-es-content32 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-header34 {
    font-size: 24px;
  }
  .home-es-content33 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-text95 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-content34 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-content35 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-content36 {
    border-top-width: 0px;
  }
  .home-es-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-es-branding2 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .home-es-burger-menu {
    display: flex;
  }
  .home-es-heading10 {
    color: var(--dl-color-primary-100);
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
  }
  .home-es-note {
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-es-caption11 {
    font-size: 18px;
    max-width: 90%;
    line-height: 23px;
    margin-right: 0px;
  }
  .home-es-statistics {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-es-stat1 {
    gap: var(--dl-space-space-unit);
  }
  .home-es-header11 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-es-caption12 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-stat2 {
    gap: var(--dl-space-space-unit);
  }
  .home-es-header12 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-es-caption13 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-stat3 {
    gap: var(--dl-space-space-unit);
  }
  .home-es-header13 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-es-caption14 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-slider1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-es-heading11 {
    font-size: 40px;
  }
  .home-es-caption15 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-es-slides1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-slider2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-slider-track1 {
    align-items: stretch;
  }
  .home-es-heading12 {
    font-size: 18px;
  }
  .home-es-caption16 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-heading13 {
    font-size: 18px;
  }
  .home-es-caption17 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-heading14 {
    font-size: 18px;
  }
  .home-es-caption18 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-heading15 {
    font-size: 18px;
  }
  .home-es-caption19 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-previous1 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-es-icon13 {
    width: 14px;
    height: 14px;
  }
  .home-es-next1 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-es-icon15 {
    width: 14px;
    height: 14px;
  }
  .home-es-objectives {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-content15 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-es-objectives-list {
    width: 100%;
    flex-direction: column;
  }
  .home-es-experience1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-es-content16 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-header19 {
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-header-container2 {
    gap: var(--dl-space-space-unit);
  }
  .home-es-heading16 {
    font-size: 40px;
  }
  .home-es-caption20 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-es-text31 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-icon17 {
    width: 40px;
    height: 40px;
  }
  .home-es-name1 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-name2 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-square1 {
    left: -20px;
    width: 20%;
    bottom: -20px;
  }
  .home-es-image15 {
    width: 100%;
  }
  .home-es-experience2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-es-square2 {
    left: -20px;
    width: 20%;
    bottom: -20px;
  }
  .home-es-image16 {
    width: 100%;
  }
  .home-es-content18 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-header21 {
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-header-container3 {
    gap: var(--dl-space-space-unit);
  }
  .home-es-heading17 {
    font-size: 40px;
  }
  .home-es-caption21 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-es-text37 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-icon26 {
    width: 40px;
    height: 40px;
  }
  .home-es-name3 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-name4 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-create {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-es-content20 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-heading18 {
    font-size: 36px;
  }
  .home-es-comparision {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-es-header-container4 {
    gap: var(--dl-space-space-unit);
  }
  .home-es-header24 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .home-es-heading19 {
    font-size: 40px;
  }
  .home-es-caption22 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-es-caption23 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-es-caption24 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-es-text59 {
    font-size: 12px;
    line-height: 14px;
  }
  .home-es-text62 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-text63 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-text64 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-text65 {
    line-height: 21px;
  }
  .home-es-text66 {
    line-height: 21px;
  }
  .home-es-text68 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-text69 {
    line-height: 21px;
  }
  .home-es-text70 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-text71 {
    line-height: 21px;
  }
  .home-es-text72 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-text73 {
    line-height: 21px;
  }
  .home-es-text74 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-text75 {
    line-height: 21px;
  }
  .home-es-text76 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-text77 {
    line-height: 21px;
  }
  .home-es-text78 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-text79 {
    line-height: 21px;
  }
  .home-es-customer {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-es-heading20 {
    font-size: 40px;
  }
  .home-es-slides2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-slider-track2 {
    align-items: stretch;
  }
  .home-es-quote1 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-es-quote2 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-author3 {
    width: 100%;
  }
  .home-es-name5 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-location1 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-quote3 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-es-quote4 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-author4 {
    width: 100%;
  }
  .home-es-name6 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-location2 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-quote5 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-es-quote6 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-author5 {
    width: 100%;
  }
  .home-es-name7 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-location3 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-quote7 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
    border-bottom-width: 1px;
  }
  .home-es-quote8 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-author6 {
    width: 100%;
  }
  .home-es-name8 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-location4 {
    width: 90%;
    font-size: 14px;
    line-height: 21px;
  }
  .home-es-previous2 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-es-icon27 {
    width: 14px;
    height: 14px;
  }
  .home-es-next2 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-es-icon29 {
    width: 14px;
    height: 14px;
  }
  .home-es-faq {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-es-header26 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .home-es-heading21 {
    font-size: 36px;
  }
  .home-es-column1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-element1 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-es-header27 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-es-element2 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-es-header28 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-es-element3 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-es-header29 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-es-element4 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-es-header30 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-es-column2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-element5 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-es-header31 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-es-element6 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-es-header32 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-es-element7 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-es-header33 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-es-element8 {
    gap: var(--dl-space-space-halfunit);
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    border-right-width: 0px;
  }
  .home-es-header34 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-es-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-es-links {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .home-es-branding1 {
    padding-top: 1px;
  }
  .home-es-hamburger {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-es-icon10 {
    width: 30px;
  }
  .home-es-mobile-menu {
    padding: 16px;
  }
  .home-es-pasted-image1 {
    height: 25px;
  }
  .home-es-button1 {
    text-align: center;
  }
  .home-es-hero-content {
    gap: var(--dl-space-space-threeunits);
  }
  .home-es-heading10 {
    color: var(--dl-color-primary-100);
    font-size: 50px;
    align-self: flex-start;
    font-style: normal;
    text-align: center;
    font-weight: 500;
  }
  .home-es-text13 {
    font-weight: 900;
  }
  .home-es-caption10 {
    font-size: 16px;
    text-align: center;
    line-height: 24px;
  }
  .home-es-link15 {
    text-align: center;
  }
  .home-es-heading11 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
  }
  .home-es-caption15 {
    width: 100%;
  }
  .home-es-text20 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-es-text21 {
    font-size: 18px;
  }
  .home-es-text25 {
    font-size: 18px;
  }
  .home-es-text27 {
    font-size: 18px;
  }
  .home-es-heading16 {
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
  }
  .home-es-caption20 {
    color: rgb(124, 124, 128);
    font-size: 16px;
    line-height: 24px;
  }
  .home-es-heading17 {
    color: rgb(255, 255, 255);
  }
  .home-es-heading18 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
  }
  .home-es-text40 {
    font-weight: 900;
  }
  .home-es-link16 {
    text-align: center;
  }
  .home-es-header24 {
    gap: 0px;
    flex-direction: column;
  }
  .home-es-heading19 {
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-style: normal;
    text-align: center;
    font-weight: 400;
  }
  .home-es-text59 {
    color: rgb(255, 255, 255);
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
  }
  .home-es-text65 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    line-height: 32px;
  }
  .home-es-heading20 {
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
  }
  .home-es-slides2 {
    width: 100%;
  }
  .home-es-slider-container2 {
    width: 100%;
  }
  .home-es-quote8 {
    color: rgb(124, 124, 128);
    font-size: 16px;
    line-height: 24px;
  }
  .home-es-link17 {
    text-align: center;
  }
  .home-es-header26 {
    gap: 0px;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-es-heading21 {
    color: rgb(255, 255, 255);
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
  }
  .home-es-header29 {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .home-es-links {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-es-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .home-es-button2 {
    justify-content: center;
  }
}
